import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip, ListItemIcon, ListItemText } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import ReexecutionDialog from './ReexecutionDialog';

const expiredReasons = [
  { name: 'COMPETITORS_CONDITIONS', label: 'Cliente operará/operó con otra institución' },
  { name: 'CLIENT_CONDITIONS', label: 'Al cliente no le gustaron las condiciones' },
  { name: 'DELAYED_EVALUATION', label: 'Se tardó mucho en la evaluación' },
  { name: 'NO_FINANCING', label: 'Cliente no necesita financiamiento' },
  { name: 'OTHER', label: 'Otro' },
];

const ExpiredDialog = ({ selectedInvoices, setSelectedInvoiceIds }) => {
  const [open, toggleOpen] = useBooleanState(false);
  return (
    <>
      <Tooltip title={(selectedInvoices.length === 0 && 'Selecciona facturas')}>
        <div>
          <MenuItem
            onClick={toggleOpen}
            disabled={selectedInvoices.length === 0}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Desechar</ListItemText>
          </MenuItem>
        </div>

      </Tooltip>
      <ReexecutionDialog
        setSelectedInvoiceIds={setSelectedInvoiceIds}
        selectedInvoices={selectedInvoices}
        reexecutionType="Expired"
        dialogTitle="Desechar Facturas"
        buttonTitle="Desechar"
        title="Estas seguro que quieres desechar las siguientes facturas?"
        open={open}
        toggleOpen={toggleOpen}
        reasonsList={expiredReasons}
      />
    </>
  );
};

ExpiredDialog.propTypes = {
  setSelectedInvoiceIds: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  ),
};

ExpiredDialog.defaultProps = {
  selectedInvoices: [],
};

export default ExpiredDialog;
