import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Table } from '@fingo/lib/components/tables';
import { formatMoney, formatRut } from '@fingo/lib/helpers';

const headers = [
  { id: 'company', label: 'Empresa', type: 'text', align: 'left', isSearchable: true, mobile: true },
  { id: 'folio', label: 'Folio', type: 'text', align: 'left', isSearchable: true, mobile: true },
  { id: 'amount', label: 'Monto', type: 'text', align: 'left', isSearchable: true, mobile: true },
];

const ReplacementTable = ({ replacements }) => {
  const dataToRows = useCallback((_data) => _data.map((replacement) => {
    const {
      id,
      replacedInvoice,
      replacingInvoice,
      replacementAmount,
      reintegrationAmount,
      owedInvoice,
      payingInvoice,
    } = replacement;
    const invoice = replacedInvoice ?? replacingInvoice ?? owedInvoice ?? payingInvoice;
    const amount = replacementAmount ?? reintegrationAmount;
    return ({
      id,
      nonSelectable: true,
      company: {
        value: invoice.company.rut,
        component: (
          <Typography
            align="left"
            variant="p1"
            style={{
              display: 'block',
              overflow: 'inherit',
            }}
          >
            {invoice.company.name}<br />{formatRut(invoice.company.rut)}
          </Typography>
        ),
        size: 'small',
      },
      folio: {
        value: invoice.folio,
        component: (
          <Typography
            align="left"
            variant="p1"
            style={{
              display: 'block',
              overflow: 'inherit',
            }}
          >
            {invoice.folio}
          </Typography>
        ),
        size: 'small',
      },
      amount: {
        value: amount,
        component: (
          <Typography
            align="left"
            variant="p1"
            style={{
              display: 'block',
              overflow: 'inherit',
            }}
          >
            ${formatMoney(amount)}
          </Typography>
        ),
        size: 'small',
      },
    });
  }), []);
  return (
    <Table
      initialOrderId="amount"
      orderDirection="desc"
      headers={headers}
      data={replacements}
      dataToRows={dataToRows}
      setNewDataOrder={() => {}}
      detailHeaders={headers.filter((header) => !header.mobile)}
      pagination={false}
      sx={{ maxHeight: '300px', overflow: 'auto' }}
    />
  );
};
export default ReplacementTable;

ReplacementTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  replacements: PropTypes.arrayOf(PropTypes.object).isRequired,
};
