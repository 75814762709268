import { useQuery } from '@apollo/client';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  useInvoicePreColumns,
  useInvoiceLucilaCustomColumns,
} from '@fingo/lib/constants';
import { MY_ASSIGNMENTS, MY_OFFERS } from '@fingo/lib/graphql';
import { React, useState } from 'react';
import { IconButton } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import { OPERATION_MANAGER_COLUMNS } from '../../constants/invoices-manager';
import {
  ExpiredDialog,
  SendAssignmentCessionTypeChangeMailDialog,
  FactoringSimulationDialog,
  DownloadExcelFromMenu,
  ReceedAction,
  MenuForActions,
} from './headers-actions';

const OperationsManager = () => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { data: selectedInvoiceData } = useQuery(MY_OFFERS, {
    variables: { id_In: selectedInvoiceIds, first: 100 },
    skip: selectedInvoiceIds.length === 0,
  });

  const selectedInvoices = selectedInvoiceData
    ? selectedInvoiceData.invoices.flatInvoices
    : [];

  return (
    <DocumentList
      trackerId="CESSION_STATUS"
      type="cession-status"
      headerTitle="Estado de cesiones"
      queryDocument={MY_ASSIGNMENTS}
      emitter
      countryFilter
      showRefresh={false}
      includeHeaders={[
        'folio-invoice-profile',
        'dateIssued',
        'company_profile',
        'receiver_sortable',
        'amountWithIva',
        'offerMonthlyRate',
        'offerDefaultRate',
        'offerRetentionRate',
        'cessionStatus',
        'requirements',
        'uploadDocumentsCommercial',
        'ratificationActions',
      ]}
      mobileHeaders={['folio', 'company_Name', 'cessionStatus']}
      showFilters
      checkboxSelection
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showInvoiceStatusFilters: true,
        showCompanyIssuerFilter: true,
        showPendingRestrictionsFilter: true,
        defaultFilterDays: 30,
        showExecutiveFilter: true,
        showHuntingFarmingFilter: true,
      }}
      allCompaniesOptions
      selectionModel={selectedInvoiceIds}
      onSelectionModelChange={(ids) => setSelectedInvoiceIds(ids)}
      customVariables={{
        hasActiveOperation: true,
        receiver_Rut: null,
        companyId: null,
        receiverId: null,
        company_MasterEntity_Rut: null,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        filterByRequestingPlatformOrAssigned: 'FINGO',
      }}
      preColumns={useInvoicePreColumns(
        useInvoiceLucilaCustomColumns(false).concat(OPERATION_MANAGER_COLUMNS),
      )}
      keepNonExistentRowsSelected
      flexEndButtons={(rowCount, commonVars) => (
        <>
          <FactoringSimulationDialog
            setSelectedInvoiceIds={setSelectedInvoiceIds}
            selectedInvoices={selectedInvoices}
          />
          <RaiseOperativeRequestIconButton
            selectedInvoices={selectedInvoiceIds}
            setSelectedInvoiceIds={setSelectedInvoiceIds}
          />
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <MenuForActions
            actions={[
              SendAssignmentCessionTypeChangeMailDialog,
              ExpiredDialog,
              ReceedAction,
              DownloadExcelFromMenu,
            ]}
            selectedInvoices={selectedInvoices}
            setSelectedInvoiceIds={setSelectedInvoiceIds}
            rowCount={rowCount}
            commonVars={commonVars}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </>
      )}
    />
  );
};

export default OperationsManager;
