import { useQuery, useReactiveVar } from '@apollo/client';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  useInvoiceLucilaCustomColumns,
  useInvoicePreColumns,
} from '@fingo/lib/constants';
import { MY_PREOFFERS_COMMERCIAL } from '@fingo/lib/graphql';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import { IconButton } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import {
  EvaluationRequestDialog,
  FactoringSimulationDialog,
  DownloadExcelFromMenu,
  ReceedAction,
  MenuForActions,
} from './headers-actions';
import { selectedInvoiceIdsVar } from '../../graphql/reactive-variables';

const DEFAULT_FILTER_DAYS = 14;

const PreoffersManager = ({ setSelectionModelCopy, ...restProps }) => {
  const selectedInvoiceIds = useReactiveVar(selectedInvoiceIdsVar);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { data: selectedInvoiceData } = useQuery(MY_PREOFFERS_COMMERCIAL, {
    variables: { id_In: selectedInvoiceIds, first: 100 },
    skip: selectedInvoiceIds.length === 0,
    onCompleted: ({ invoices: { edges } }) => {
      if (setSelectionModelCopy) {
        setSelectionModelCopy(edges.map((edge) => edge.node.preoffer));
      }
    },
  });

  const selectedInvoices = useMemo(
    () => (selectedInvoiceData
      ? selectedInvoiceData.invoices.edges.map((edge) => edge.node)
      : []),
    [selectedInvoiceData],
  );

  const dateIssuedGte = useMemo(
    () => formatYearMonthDay(moment().subtract(DEFAULT_FILTER_DAYS, 'days')),
    [],
  );

  const isRowSelectable = useCallback(
    ({ row }) => ['EVALUATING', 'PREOFFER'].includes(row.invoiceStatus),
    [],
  );

  const preColumns = useInvoicePreColumns(useInvoiceLucilaCustomColumns(false));

  return (
    <DocumentList
      trackerId="PREOFFERS-MANAGER"
      type="available-factoring"
      headerTitle="Clientes con preofertas"
      queryDocument={MY_PREOFFERS_COMMERCIAL}
      allCompaniesOptions
      countryFilter
      customVariables={{
        hasValidPreoffer: true,
        receiver_Rut: null,
        receiverId: null,
        dateIssued_Gte: dateIssuedGte,
      }}
      checkboxSelection
      isRowSelectable={isRowSelectable}
      includeHeaders={[
        'folio-invoice-profile',
        'dateIssued',
        'company_profile',
        'receiver_sortable',
        'amountWithIva',
        'preoffer_MonthlyRate',
        'defaultRate',
        'retentionRate',
        'executiveAssigned',
        'factoringStatus',
        'uploadDocumentsCommercial',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_Name',
        'preoffer_MonthlyRate',
        'defaultRate',
        'amountWithIva',
      ]}
      onSelectionModelChange={(ids) => selectedInvoiceIdsVar(ids)}
      showFilters
      defaultFilterProps={{
        showPreoffersFilters: true,
        showHuntingFarmingFilter: true,
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        showLightningFilter: true,
        showValidSiiCredentialsFilter: true,
        defaultFilterDays: DEFAULT_FILTER_DAYS,
        showExecutiveFilter: true,
        preHeaderComponent: true,
      }}
      keepNonExistentRowsSelected
      flexEndButtons={(rowCount, commonVars) => (
        <>
          <FactoringSimulationDialog
            selectedInvoices={selectedInvoices}
            columns={[
              'folio',
              'company_MasterEntity_Name',
              'receiver_sortable',
              'amountWithIva',
              'preoffer_MonthlyRate',
              'defaultRate',
              'retentionRate',
              'dateToPay',
              'priceDifference',
            ]}
          />
          <EvaluationRequestDialog selectedInvoices={selectedInvoices} />
          <RaiseOperativeRequestIconButton selectedInvoices={selectedInvoiceIds} />
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <MenuForActions
            actions={[ReceedAction, DownloadExcelFromMenu]}
            selectedInvoices={selectedInvoices}
            setSelectedInvoiceIds={selectedInvoiceIdsVar}
            rowCount={rowCount}
            commonVars={commonVars}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </>
      )}
      selectionModel={selectedInvoiceIds}
      preColumns={preColumns}
      {...restProps}
    />
  );
};

PreoffersManager.propTypes = {
  setSelectionModelCopy: PropTypes.func,
};

PreoffersManager.defaultProps = {
  setSelectionModelCopy: null,
};

export default PreoffersManager;
